<!-- 订单列表 -->
<template>
  <div>
    <el-card>
      <div class="topsearch">
        <div class="display">
          <div>订单编号</div>
          <el-input
            size="small"
            v-model="dataFrom.orderCode"
            placeholder="搜索"
          >
          </el-input>
        </div>
        <div class="display">
          <div>需求编号</div>
          <el-input
            size="small"
            v-model="dataFrom.requiredCode"
            placeholder="搜索"
          >
          </el-input>
        </div>
        <div class="display">
          <div>日期</div>
          <el-date-picker
            v-model="dataFrom.receiveTime"
            value-format="yyyy-MM-dd"
            type="date"
            size="small"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="btns">
          <el-button
            size="small"
            type="primary"
            class="submit"
            @click="inputChange"
          >
            搜索
          </el-button>
          <el-button
            size="small"
            type="primary"
            class="submit"
            @click="resetCli"
          >
            重置
          </el-button>
        </div>
      </div>
      <el-radio-group
        v-model="dataFrom.orderStatus"
        @change="changeRadio"
        size="small"
      >
        <el-radio-button
          :label="item.id"
          v-for="item in radioList"
          :key="item.id"
        >
          {{ item.name }}
        </el-radio-button>
      </el-radio-group>
      <el-scrollbar class="list" v-if="list.length > 0">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click.stop="toDetail(item.id)"
        >
          <div class="flex">
            <div style="flex: 1">
              <el-descriptions>
                <el-descriptions-item label="订单编号">{{
                  item.orderCode
                }}</el-descriptions-item>
                <el-descriptions-item label="需求单号">{{
                  item.orderCode
                }}</el-descriptions-item>

                <el-descriptions-item label="工程名称">{{
                  item.orderDriverName
                }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="接单时间">{{
                  item.receiveTime
                }}</el-descriptions-item>
                <el-descriptions-item label="验收时间">{{
                  item.checkTime
                }}</el-descriptions-item>
                <el-descriptions-item label="结束时间">{{
                  item.overTime
                }}</el-descriptions-item>
                <el-descriptions-item
                  :label="
                    userInfo.businessStatus == 2
                      ? '发布企业'
                      : userInfo.companyStatus == 2
                      ? '接单车主'
                      : ''
                  "
                  >{{
                    userInfo.businessStatus == 2
                      ? item.requireIssuerName
                      : userInfo.companyStatus == 2
                      ? item.orderDriverName
                      : ''
                  }}</el-descriptions-item
                >
                <el-descriptions-item label="支付方式">{{
                  item.payTypeCode == 0
                    ? '完工付'
                    : item.payTypeCode == 1
                    ? '现结'
                    : item.payTypeCode == 2
                    ? '日结'
                    : item.payTypeCode == 3
                    ? '月结'
                    : item.payTypeCode == null
                    ? '无'
                    : '季付'
                }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions>
                <el-descriptions-item label="项目地址"
                  >{{ item.projectProvince }}{{ item.projectCity
                  }}{{ item.projectCountry
                  }}{{ item.projectAddress }}</el-descriptions-item
                >
              </el-descriptions>
            </div>
            <div class="status">
              {{
                item.orderStatus == 0
                  ? '已接单'
                  : item.orderStatus == 1
                  ? '执行中'
                  : item.orderStatus == 2
                  ? '待验收'
                  : item.orderStatus == 3
                  ? '已验收'
                  : item.orderStatus == 4
                  ? '已支付'
                  : item.orderStatus == 5
                  ? '已开票'
                  : item.orderStatus == 6
                  ? '已完成'
                  : '已取消'
              }}
            </div>
          </div>
          <div v-for="(v, i) in item.cereOrderDetails" :key="i" class="items">
            <!-- <div class="tag"> -->
            <el-descriptions>
              <el-descriptions-item label="设备名称">{{
                v.vehicleName
              }}</el-descriptions-item>
              <el-descriptions-item label="设备类型"
                >{{ v.firstLevelType }}/{{
                  v.secondLevelType
                }}</el-descriptions-item
              >
              <el-descriptions-item label="设备规格">{{
                v.vehicleModel
              }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions>
              <el-descriptions-item label="进场日期">{{
                v.slottingTime
              }}</el-descriptions-item>
              <el-descriptions-item label="租赁方式">{{
                v.rentType
              }}</el-descriptions-item>
              <el-descriptions-item label="工作量"
                >{{ v.leaseWork }}{{ v.leaseUnit }}</el-descriptions-item
              >
            </el-descriptions>
            <el-descriptions>
              <el-descriptions-item label="机械数量">{{
                v.count
              }}</el-descriptions-item>
              <el-descriptions-item label="单价">{{
                v.unitPrice
              }}</el-descriptions-item>
            </el-descriptions>
          </div>
          <div
            v-if="userInfo.businessStatus == 2"
            class="btn"
            style="text-align: right; margin-top: 10px"
          >
            <el-button
              @click.stop="begin(item.id, 0, '拒绝理由')"
              type="primary"
              size="small"
              v-show="item.button.isRefuseOrder"
              class="submit"
              >拒绝接单</el-button
            >
            <el-button
              @click.stop="begin(item.id, 1)"
              type="primary"
              size="small"
              v-show="item.button.isExecute"
              class="submit"
              >立即执行</el-button
            >
            <el-button
              @click.stop="begin(item.id, 2, '终止理由')"
              type="primary"
              size="small"
              class="submit"
              v-show="item.button.isTerminatExecute"
              >终止执行</el-button
            >
            <el-button
              @click.stop="begin(item.id, 3, '验收备注')"
              type="primary"
              size="small"
              class="submit"
              v-show="item.button.isIssueCheck"
              >发起验收</el-button
            >
            <el-button
              @click.stop="begin(item.id, 4, '审核改价')"
              type="primary"
              size="small"
              class="submit"
              v-show="item.button.isCheckPrice"
              >审核改价</el-button
            >
          </div>
          <div
            v-if="userInfo.companyStatus == 2"
            class="btn"
            style="text-align: right; margin-top: 10px"
          >
            <el-button
              @click.stop="begin(item.id, 4, '申请改价')"
              type="primary"
              size="small"
              class="submit"
              v-show="item.button.isModfiyPrice"
              >修改金额</el-button
            >
            <el-button
              @click.stop="toDetail(item.id, '改价验收')"
              type="primary"
              size="small"
              class="submit"
              v-show="item.button.isCheck"
              >验收</el-button
            >
            <el-button
              @click.stop="begin(item.id, 5, '终止理由')"
              type="primary"
              size="small"
              class="submit"
              v-show="item.button.isStop"
              >终止</el-button
            >
          </div>
        </div>
      </el-scrollbar>
      <el-empty :image-size="200" v-else></el-empty>
      <el-pagination
        @current-change="handleCurrentChange"
        background
        layout=" prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      :title="dialogVisibleTitle"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div v-if="remark != null && dialogVisibleTitle == '申请改价'">
        <h3>车主拒绝理由：{{ remark }}</h3>
      </div>
      <div style="margin: 20px 0">
        <el-input
          v-model="modifyParam.afterModifyPrice"
          placeholder="请输入订单金额"
          size="small"
          :disabled="dialogVisibleTitle == '审核改价'"
        ></el-input>
      </div>
      <div>
        <el-input
          v-model="modifyParam.remark"
          placeholder="请输入订单备注"
          size="small"
          :disabled="dialogVisibleTitle == '审核改价'"
        ></el-input>
      </div>
      <div style="margin-top: 20px" v-if="dialogVisibleTitle == '审核改价'">
        原价：<span style="color: red"
          >￥{{ modifyParam.beforeModifyPrice }}</span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handCancel">{{
          dialogVisibleTitle == '申请改价' ? '取消' : '不同意'
        }}</el-button>
        <el-button
          size="small"
          type="primary"
          @click="editPrice(dialogVisibleTitle)"
          >{{
            dialogVisibleTitle == '申请改价' && remark != null
              ? '重新提交'
              : dialogVisibleTitle == '申请改价'
              ? '确定'
              : '同意'
          }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getOrderList,
  orderOperation,
  modifyPrice,
  orderModifyUpdate,
  checkPass,
} from '@/api/order'
import { debounce } from '@/utils/validate'
import { getUser } from '@/api/user'

export default {
  data() {
    return {
      userInfo: {},
      radioList: [
        { id: 0, name: '已接单' },
        { id: 1, name: '执行中' },
        { id: 2, name: '待验收' },
        { id: 3, name: '已验收' },
        { id: 4, name: '已支付' },
        { id: 5, name: '已开票' },
        { id: 6, name: '已完成' },
        { id: 7, name: '已取消' },
      ],
      list: [],
      dataFrom: {
        page: 1,
        pageSize: 10,
        orderStatus: 0,
        orderCode: '',
        requiredCode: '',
        receiveTime: '',
      },
      total: 0,
      dialogVisible: false,
      modifyParam: {
        afterModifyPrice: null,
        orderId: null,
        remark: '',
        beforeModifyPrice: 0,
      },
      dialogVisibleTitle: '',
      remark: null,
    }
  },
  created() {
    if (this.$route.query.type) {
      sessionStorage.setItem('orderStatus', this.$route.query.type)
    }
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if (sessionStorage.getItem('orderStatus')) {
      this.dataFrom.orderStatus = sessionStorage.getItem('orderStatus')
    }
    this.getOrderList()
  },
  methods: {
    changeRadio(e) {
      this.getOrderList()
      sessionStorage.setItem('orderStatus', e)
    },
    getOrderList() {
      getOrderList(this.dataFrom).then((res) => {
        this.list = res.data.list
        this.total = res.data.total
      })
    },
    handleCurrentChange(val) {
      this.dataFrom.page = val
      this.getOrderList()
    },
    inputChange: debounce(function () {
      this.getOrderList()
    }, 300),
    // 重置
    resetCli() {
      this.dataFrom = {
        page: 1,
        pageSize: 10,
        orderStatus: 0,
      }
      this.getOrderList()
    },
    toDetail(id, val) {
      this.$router.push({
        path: '/orderDetail',
        query: {
          id: id,
          val: val,
        },
      })
    },
    begin(id, index, texts) {
      if (index == 0 || index == 2 || index == 5) {
        this.$prompt(`请填写${texts}`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          let cereOrderParam = {
            buttonType: index,
            orderId: id,
            reason: value,
          }
          orderOperation(cereOrderParam).then((res) => {
            this.$message.success('提交成功')
            this.getOrderList()
          })
        })
      } else if (index == 3) {
        // 车主验收操作，
        this.$confirm('是否需要修改价格', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '是',
          cancelButtonText: '直接验收',
          type: 'warning',
        })
          .then(() => {
            this.$router.push({
              path: '/deviceShow',
              query: {
                id: id,
                orderStatus: this.dataFrom.orderStatus,
              },
            })
          })
          .catch((action) => {
            if (action == 'cancel') {
              this.$prompt(`请填写${texts}`, '订单验收', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(({ value }) => {
                let cereOrderParam = {
                  buttonType: index,
                  orderId: id,
                  reason: value,
                }
                orderOperation(cereOrderParam).then((res) => {
                  this.$message.success('提交成功')
                  this.getOrderList()
                })
              })
            }
          })
      } else if (index == 4) {
        // 审核改价
        this.dialogVisible = true
        this.buttonType = index
        this.id = id
        this.dialogVisibleTitle = texts
        console.log(texts)
        if (texts == '审核改价') {
          orderModifyUpdate(id).then((res) => {
            this.modifyParam = res.data
            this.modifyParam.beforeModifyPrice = res.data.beforeModifyPrice.toFixed(
              2
            )
          })
        } else if (texts == '申请改价') {
          orderModifyUpdate(id).then((res) => {
            this.remark = res.data.remark
          })
        }
      } else if (index == 1) {
        this.$confirm('是否执行？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let cereOrderParam = {
              buttonType: index,
              orderId: id,
            }
            orderOperation(cereOrderParam).then((res) => {
              this.$message.success('提交成功')
              this.getOrderList()
            })
          })
          .catch(() => {})
      } else if (index == 6) {
        this.$confirm('是否验收？', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '通过',
          cancelButtonText: '不合格',
          type: 'warning',
        })
          .then(() => {
            let cereOrderParam = {
              buttonType: 4,
              orderId: id,
              acceptStatus: true,
            }
            orderOperation(cereOrderParam).then((res) => {
              this.$message.success('提交成功')
              this.getOrderList()
            })
          })
          .catch((action) => {
            console.log(action)
            if (action == 'cancel') {
              this.$prompt(`请填写${texts}`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(({ value }) => {
                let cereOrderParam = {
                  buttonType: 4,
                  orderId: id,
                  reason: value,
                  acceptStatus: false,
                }
                orderOperation(cereOrderParam).then((res) => {
                  this.$message.success('提交成功')
                  this.getOrderList()
                })
              })
            }
          })
      }
    },
    handCancel() {
      if (this.dialogVisibleTitle == '申请改价') {
        this.dialogVisible = false
      } else {
        this.dialogVisible = false
        this.$prompt(
          `备注：如有问题请联系平台服务电话400-000000`,
          '请填写拒绝理由',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(({ value }) => {
          let cereOrderParam = {
            orderId: this.id,
            remark: value,
            agree: false,
          }
          checkPass(cereOrderParam).then((res) => {
            this.$message.success('提交成功')
            this.getOrderList()
          })
        })
      }
    },
    editPrice(v) {
      if (!this.modifyParam.afterModifyPrice) {
        this.$message.warning('请填写改价金额')
        return
      }
      this.modifyParam.orderId = this.id
      console.log(v)
      if (v == '审核改价') {
        // 车主审核企业提交的修改金额操作
        let cereOrderParam = {
          orderId: this.id,
          agree: true,
        }
        checkPass(cereOrderParam).then((res) => {
          this.dialogVisible = false
          this.$message.success('提交成功')
          this.getOrderList()
        })
      } else {
        // 企业提交修改金额操作
        modifyPrice(this.modifyParam).then((res) => {
          this.dialogVisible = false
          this.$message.success('提交成功')
          this.modifyParam = {}
          this.getOrderList()
        })
      }
    },
  },
}
</script>
<style lang='scss' scoped>
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
::v-deep .el-descriptions__body {
  background-color: #f5f7fa;
}
.el-pagination {
  text-align: right;
}
.topsearch {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .display {
    display: flex;
    align-items: center;
    .el-input {
      width: 70%;
    }
    div {
      margin-right: 10px;
    }
  }
  .btns {
    margin-left: auto;
    display: flex;
  }
}
.list {
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  height: 500px;
  border-radius: 5px;

  .item:hover {
    color: #fcb110;
    cursor: pointer;
    ::v-deep .el-descriptions__body {
      // color: #fcb110;
    }
  }
  .item {
    margin-bottom: 10px;
    padding: 10px;
    transition: all 0.2s;
    border-radius: 5px;
    transition: all 0.5s;
    background-color: #f5f7fa;
    .flex {
      display: flex;
      justify-content: space-between;
      .status {
        color: orange;
      }
    }
    .items {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-top: 10px;
      .price {
        font-size: 12px;
        color: #999;
      }
    }
  }
}
</style>